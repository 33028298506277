import React, { Component } from 'react';
import { graphql } from 'gatsby';

import Template from './../layouts/Template';
import styled from 'styled-components';

const BlogPost = styled.section`
	${tw`pt-24`};
	.box {
		${tw`text-left`};
	}
	.title-big {
		${tw`sm:text-128`};
		top: -0.55em;
		margin-bottom: -0.35em;
	}
	.subtitle {
		${tw`mb-8`};
	}
	h2,
	h3,
	h4,
	h5,
	h6 {
		${tw`mb-4`};
	}
	img {
		${tw`shadow-light mb-8`};
	}

	.slider {
		${tw`flex items-center overflow-x-scroll`};
	}
`;
const StyledP = styled.div`
	p {
		${tw`text-20 font-sans`};
		max-width: 800px;
	}
	a {
		${tw`border-secondary`};
		padding: 3px;
		&:hover {
			${tw`bg-secondary text-primary`};
		}
	}
`;

class BlogPostTemplate extends Component {
	render() {
		const post = this.props.data.markdownRemark;
		const siteTitle = this.props.data.site.siteMetadata.title;
		const { previous, next } = this.props.pageContext;

		return (
			<Template title={`${post.frontmatter.title} | ${siteTitle}`} desc={post.excerpt}>
				<BlogPost>
					<div className="wrapper">
						<div className="container">
							<div className="box">
								<h1 className="title-big">{post.frontmatter.title}</h1>
								<div className="subtitle">{post.frontmatter.date}</div>
								<StyledP dangerouslySetInnerHTML={{ __html: post.html }} />
							</div>
						</div>
					</div>
				</BlogPost>

				{/* <ul>
					<li>
						{previous && (
							<Link to={`${previous.fields.slug}`} rel="prev">
								← {previous.frontmatter.title}
							</Link>
						)}
					</li>
					<li>
						{next && (
							<Link to={`${next.fields.slug}`} rel="next">
								{next.frontmatter.title} →
							</Link>
						)}
					</li>
				</ul> */}
			</Template>
		);
	}
}

export default BlogPostTemplate;

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!) {
		site {
			siteMetadata {
				title
				author
			}
		}
		markdownRemark(fields: { slug: { eq: $slug } }) {
			id
			html
			excerpt
			frontmatter {
				title
				date(formatString: "MMMM DD, YYYY")
			}
		}
	}
`;
